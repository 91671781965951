<template>
  <!-- 智慧园区 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧园区</div>
      <div class="title-p">
        未来智慧园区，融合新一代信息与通信技术，迅捷信息采集、高速信息传输、高度集中计算、智能事务处理和无所不在的服务提供能力，实现园区内及时、互动、整合的信息感知、传递和处理，以提高园区产业集聚能力、企业经济竞争力、园区可持续发展为目标的先进园区发展理念。
      </div>
    </div>
    <div class="function">
      <div class="function-title">平台架构</div>
      <img src="../../assets/image/parkImg/组 255.png" alt="" width="100%" />
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
      <div class="scenes-box">
        <div class="scenes-bg"></div>
        <div class="scenes-text">
          <div class="title">
            同步监测党建、安监、经济、治安、交通、环境等多维园区辖区常态领域运行
          </div>
          <div class="text-box">
            <div class="text-box-item">
              <p>立案率</p>
              <p>处置率</p>
              <p>按期处置率</p>
              <p>高发问题</p>
              <p>来源分析</p>
            </div>
            <div class="text-box-item">
              <p>有效报案数</p>
              <p>警情分布</p>
              <p>园区刷卡数</p>
            </div>
            <div class="text-box-item">
              <p>交通路况</p>
              <p>公共自行车</p>
              <p>停车泊位</p>
              <p>公交车</p>
              <p>地铁</p>
            </div>
            <div class="text-box-item">
              <p>地区生产总值</p>
              <p>规上工业产值</p>
              <p>工业增加值</p>
              <p>固定资产投资</p>
            </div>
            <div class="text-box-item">
              <p>AQI指数</p>
              <p>主要污染物</p>
              <p>PM2.5</p>
              <p>企业废水排放</p>
              <p>企业废气排放</p>
            </div>
            <div class="text-box-item">
              <p>危险源</p>
              <p>防护目标</p>
              <p>避难场所</p>
              <p>物资装备</p>
              <p>应急值守</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">云服务平台</div>
          <div class="sceneContent">
            在园区已建的有线、无线网络及园区数据中心的基础上，通过各子系统的数据整合，构建云办公、云商业、云管理等服务系统，提供完整的智慧园区应用。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">能耗管理系统</div>
          <div class="sceneContent">
            对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">立体综合安防系统</div>
          <div class="sceneContent">
            安防重点：开放性园区在室外形成网格化、无视角、无缝连接的监控（周界）。<br />
            人脸识别：在园区出入口、核心受控区等地点形成更精细化的人员管理功能。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">资产管理系统</div>
          <div class="sceneContent">
            对日常管理中的资产信息进行实时监控、记录和自动更新，同时采集人员信息，真正实现“帐、卡、物、人”相符；将原来分散的资产管理信息集中起来，组合成为一个整体的资产管理信息平台，从而避免因人为因素造成的信息失真引起管理效能的下降。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    go(i) {
      if (i == 1) {
        this.$router.push("CloudServices");
      }
      if (i == 2) {
        this.$router.push("consumptionManagement");
      }
      if (i == 3) {
        this.$router.push("Stereosynthesis");
      }
      if (i == 4) {
        this.$router.push("assetManagement");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/parkImg/bg.png") no-repeat;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .nh {
    background: url("../../assets/image/parkImg/nhbg.png") no-repeat;
    margin-top: 50px;
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }

    .scenes-box {
      .scenes-bg {
        width: 100%;
        height: 420px;
        background: url("../../assets/image/parkImg/scenes.png") no-repeat;
        background-size: 100%;
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;
      height: 500px;
      display: flex;
      background-color: #fff;
      .left {
        width: 65%;
        height: 500px;
        background: url("../../assets/image/parkImg/services.png") no-repeat;
        background-size: 100%;
      }
      .right {
        width: 30%;
        padding: 6%;
        box-sizing: border-box;
        div {
          font-size: 24px;
          color: #6b6b6b;
        }
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
    .nh-box {
      display: flex;
      text-align: center;
      justify-content: space-between;

      .nh-a {
        width: 32%;
        .nh-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          color: #3651d8;
          background-color: #d6ddff;
        }
        .nh-grid {
          display: flex;
          margin: 3% 0;
          .grid-left {
            width: 60%;
            height: 200px;
            background: url("../../assets/image/parkImg/nh1.png");
            padding: 5%;
            text-align: left;
            box-sizing: border-box;
            span {
              color: #fff;
              font-size: 20px;
            }
          }
          .grid-right {
            width: 40%;
            height: 200px;
            padding: 3%;
            display: flex;
            text-align: left;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            background-color: #fff;
            .grid-line {
              font-size: 0.8vw;
              color: #6b6b6b;
              height: 25%;
              i {
                vertical-align: middle;
                display: inline-block;
                width: 6px;
                height: 6px;
                margin: 0 5%;
                background: #3651d8;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 80%;
              }
            }
          }
        }
        .bg2 {
          background: url("../../assets/image/parkImg/nh2.png") no-repeat !important;
        }
        .bg3 {
          background: url("../../assets/image/parkImg/nh3.png") no-repeat !important;
        }
        .bg4 {
          background: url("../../assets/image/parkImg/nh4.png") no-repeat !important;
        }
        .bg5 {
          background: url("../../assets/image/parkImg/nh5.png") no-repeat !important;
        }
        .bg6 {
          background: url("../../assets/image/parkImg/nh6.png") no-repeat !important;
        }
      }
    }
    .security-text {
      font-size: 1.4vw;
      color: #6b6b6b;
      margin-left: 25%;
      // transform: translateX(-50%);
      p {
        margin: 1% 0;
      }
    }
    .security-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      margin-top: 40px;
      .security-item {
        width: 24%;
        img {
          width: 100%;
        }
        div {
          margin: 30px 0;
        }
      }
    }
  }
}
.scene {
  padding: 0 10%;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 180px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
      box-sizing: border-box;
      padding: 0 15px;
      > span {
        display: inline-block;
        width: 46%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(168, 168, 168, 0.5);
        border-radius: 0px;
        margin: 15px 2% 0 0;
        color: #fff;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
      }
    }
  }
}
.img2 {
  background: url("../../assets/image/parkImg/组 328.png") no-repeat;
  background-size: 100% 100%;
}
.img3 {
  background: url("../../assets/image/parkImg/组 302.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/parkImg/组 326.png") no-repeat;
  background-size: 100% 100%;
}
.img5 {
  background: url("../../assets/image/parkImg/组 327.png") no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/parkImg/组 183.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/parkImg/组 179.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/parkImg/组 186.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/parkImg/组 181.png") no-repeat;
  background-size: 100% 100%;
}
</style>
